import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

// 按需引入
import {
  RadioGroup,
  Button,
  Tooltip,
  Input,
  Dialog,
  Pagination,
  Message,
  Carousel,
  CarouselItem,
  Radio,
  Form,
  FormItem,
  Upload,
  Image,
  Card,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  InputNumber,
  DatePicker,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(VueAxios, axios)
// element
Vue.use(Button).use(Tooltip).use(Input).use(Dialog).use(Pagination).use(Carousel).use(CarouselItem).use(Radio).use(Form)
.use(FormItem).use(RadioGroup).use(Upload).use(Image).use(Card).use(Carousel).use(CarouselItem).use(Checkbox).use(CheckboxGroup).use(Select).use(Option).use(InputNumber)
.use(DatePicker).use(Message);

Vue.config.productionTip = false
//引入公共样式
import '../src/common/common.scss';
//引入移动端样式：测试阶段
import '../src/common/moblie.scss';
//手机自适应js
// import '../src/utils/selfAdaptation'http://180.97.183.252:2096
//图片地址
 const httpUrl = 'https://3k.youqn.net:2096'
//  const httpUrl = 'http://180.97.183.252:2096'
 Vue.prototype.$httpUrl = httpUrl
 Vue.prototype.$message = Message

new Vue({
  router,
  httpUrl,
  render: h => h(App)
}).$mount('#app')
