<template>
  <div id="app">
    <my-header class="head-fixed"/>
    <router-view class="app-view" :key="$route.query.time"/>
    <MyFooter/>
  </div>
</template>

<script>
import MyHeader from "@/components/MyHeader/MyHeader.vue";
import MyFooter from "@/components/MyFooter/MyFooter.vue";

export default {
  name: 'App',
  components: {
    MyHeader,
    MyFooter,
  },
  mounted() {
    window.document.title = '3K.cm'
  },
}
</script>

<style lang="scss">
.head-fixed{
  width: 100%;
  position: fixed;
  top:0px;
  z-index: 9999;
}
.app-view{
  margin-top: 80px;
}
</style>
