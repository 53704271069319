import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  // 配置路由们
  routes: [
    {
      // 默认路由：首页
      path: "/",
      component: () =>
        import('../pages/MyHome/MyHome.vue'),
      name: "MyHome",
    },
    {
      // 列表页
      path: "/MyList",
      component: () =>
        import('../pages/MyList/MyList.vue'),
      name: "MyList",
    },
    {
      // 文章内页
      path: "/MyWritings",
      component: () =>
        import('../pages/MyWritings/MyWritings.vue'),
      name: "MyWritings",
    },
    {
      // 广告内页
      path: "/MyAd",
      component: () =>
        import('../pages/MyAd/MyAd.vue'),
      name: "MyAd",
    },
    {
      // 发布页
      path: "/releaseAd",
      component: () =>
        import('../pages/releaseAd/releaseAd.vue'),
      name: "releaseAd",
    },
  ],
  scrollBehavior() {
      return {
          x: 0,
          y: 0
      }
  }
})