import request from '../request/request'

export default {

    // 栏目取文章
    getColumnt(data) {
        return request({
            url: `/article/getAllArticleByCategoryId/${data.id}`,
            method: 'post',
            data: data
        })
    },
    // 根据id取文章
    getArticleById(id) {
        return request({
            url: `/article/getById/${id}`,
            method: 'get',
            data: {}
        })
    },
    // 获取广告瀑布流
    getAllAd(data) {
        // return http.post(`/advertisements/admin/getAllAd`, data)
        // return http.post(`/advertisements/waterfall/${data.id}`, data)
        return request({
            url: `/advertisements/waterfall/${data.id}`,
            method: 'post',
            data: data
        })
    },
    // 根据id取广告
    getAdById(id) {
        return request({
            url: `/advertisements/getAd/${id}`,
            method: 'get',
            data: {}
        })
    }

}
 

// 上传图片
export function uploadImages(files) {
    return request({
        url: `/upload/uploadImages`,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary5nQGIG5v26I9DdFB'
        },
        data: files
    })
}
// 创建广告
export function addAdvertisement(data) {
    return request({
        url: `/advertisements/admin/addAdvertisement`,
        method: 'post',
        data: data
    })
}

