<template>
  <div>
    <div class="myFooter-div">
      <div class="root-content">
        <!-- logo和友情链接 -->
        <div class="myFooter-box1">
          <div class="myFooter-img1" @click="openUrl(logoUrl?.linkUrl)">
            <img :src="logoUrl?.icon | montageImgUrl(that)" alt="" />
          </div>
          <div class="myFooter-txts">
            <div class="myFooter-txt" @click="openUrl(n.linkUrl)" v-for="(n, i) in friendList" :key="i">
              {{ n.title }}
            </div>
          </div>
        </div>
        <!-- 富文本用户协议 -->
        <div class="myFooter-box2"><div v-html="footInfoList"></div></div>
        <!-- 备案号 -->
        <div class="myFooter-box3">
          <div class="myFooter-img2">
            <img :src="filingInfo?.imageUrl | montageImgUrl(that)" alt="" />
          </div>
          <div class="myFooter-txt2"><div v-html="filingInfo?.content"></div></div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import api from "@/api/api.js";
export default {
  name: "MyFooter",
  data() {
    return {
      that: this,
      //友情链接
      friendList: [],
      //底部logo
      logoUrl: null,
      //底部信息
      footInfoList: null,
      //备案信息
      filingInfo: null,
    };
  },
  created() {
    this.getLogo(); //获取底部logo
  },
  filters: {
    montageImgUrl(url, that) {
      if (url?.indexOf("http") == -1) {
        return that.$httpUrl + url;
      } else {
        return url;
      }
    },
  },
  methods: {
    openUrl(url) {
      if (!/^https?:\/\//i.test(url)) {
        // 如果不包含，则在字符串前加上 "https://"
        url = "https://" + url;
      }
      window.open(url);
    },
    // 底部logo
    getLogo() {
      let data = {
        id: 228,
        current: 1,
        size: 5,
        data: {},
      };
      api.getColumnt(data).then((res) => {
        // console.log(res.records, "底部logo");
        this.logoUrl = res.records[0];
        this.getFriendLink();//友情链接
      });
    },
    // 友情链接
    getFriendLink() {
      let data = {
        id: 229,
        current: 1,
        size: 15,
        data: {},
      };
      api.getColumnt(data).then((res) => {
        // console.log(res.records, "友情链接");
        this.friendList = res.records;
        this.getFootInfo();
      });
    },
    // 底部信息
    getFootInfo() {
      let data = {
        id: 230,
        current: 1,
        size: 5,
        data: {},
      };
      api.getColumnt(data).then((res) => {
        // console.log(res.records, "底部信息");
        this.footInfoList = res.records[0]?.content;
        this.getFilingInfo();
      });
    },
    // 备案信息
    getFilingInfo() {
      let data = {
        id: 231,
        current: 1,
        size: 5,
        data: {},
      };
      api.getColumnt(data).then((res) => {
        // console.log(res.records, "备案信息");
        this.filingInfo = res.records[0];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.myFooter-div {
  width: 100%;
  height: 284px;
  display: flex;
  justify-content: center;
  background-color: #24262b;
  padding: 37px 0px 28px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .myFooter-box1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;
    border-bottom: 1px solid #3a3a3a;
    .myFooter-img1 {
      width: 223px;
      height: 38px;
      margin-right: 24px;
    }
    .myFooter-txts {
      display: flex;
      align-items: center;
      color: #fff;
      .myFooter-txt {
        padding-left: 14px;
        margin-right: 14px;
        border-left: 1px solid #fff;
      }
    }
  }
  .myFooter-box2{
    color: #fff;
  }
  .myFooter-box3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .myFooter-img2 {
      width: 26px;
      height: 31px;
      margin-right: 10px;
    }
    .myFooter-txt2 {
      color: #fff;
    }
  }
}
</style>

    
    