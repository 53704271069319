import axios from 'axios'

// 重新请求次数
axios.defaults.retry = 2;
// 重新请求时间
axios.defaults.retryDelay = 10000;

const service = axios.create({
    // baseURL: 'https://3k.youqn.net:2096',
    baseURL: 'https://3k.youqn.net:2096',
    // baseURL: 'http://180.97.183.252:2096',
})
// new 对象实例
// request interceptor
service.interceptors.request.use(
    config => {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {}
        const Token = userInfo.utoken
        if (Token) config.headers['u-token'] = Token
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    // response => {
    //     const res = response.data
    //     return res;
    // },
    (res) => {
        const data = res.data
        return data;
    },

    async (err) => {
        const config = err.config;
        if (!config || !config.retry) return Promise.reject(err);
        // 手动给config添加当前重试次数的属性
        config.__retryCount = config.__retryCount || 0;

        // 达到上限时reject
        if (config.__retryCount >= config.retry) {
            return Promise.reject(err);
        }

        // 重试次数+1
        config.__retryCount += 1;

        // 定时器在达到重试间隔时resolve往下执行
        const backoff = new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.retryDelay || 1);
        });

        // 返回一个promise，在定时器结束后重新调用timeOutRequest(config)发起请求，timeOutRequest之前创建的实例
        await backoff
        return await service(config)
    }
)

export default service