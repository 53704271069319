<template>
  <div>
    <div class="head-div">
      <div class="root-content">
        <div class="head-box">
          <!-- logo -->
          <div @click="openUrl(logoInfo?.linkUrl)" class="head-logo">
            <img :src="logoInfo?.icon | montageImgUrl(that)" alt="" />
          </div>
          <!-- 首页、游戏产品、加入我们等 -->
          <div class="head-items" :class="isClick ? 'items-top' : ''">
            <div @click="setRouter('MyHome')" class="head-item selected">
              首页
            </div>
            <div
              class="head-item"
              @click="openUrl(n?.linkUrl)"
              v-for="(n, i) in headItemList"
              :key="i"
            >
              {{ n?.title }}
            </div>
          </div>
          <!-- 移动端的选项按钮 -->
          <div
            class="head-moblie-btn"
            @click="isClick = !isClick"
            :class="isClick ? 'head-moblie-btn-active' : ''"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <!-- 用户头像和用户名等 -->
          <div class="head-userInfo">
            <div @click="setRouter('MyHome')">
              <div class="userInfo-img">
                <img src="../../assets/xiaolaohu.svg" alt="" />
              </div>
              <div class="userInfo-name">登录</div>
              <div class="userInfo-btns">
                <div>我要发布</div>
                <div>退出登录</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";

export default {
  name: "MyHeader",
  data() {
    return {
      that: this,
      //头部列表功能
      headItemList: [],
      //logo链接
      logoInfo: null,
      // 移动端是否点击了头部选项, true为点击出现下拉选项
      isClick: false,
    };
  },
  filters: {
    montageImgUrl(url, that) {
      if (url?.indexOf("http") == -1) {
        return that.$httpUrl + url;
      } else {
        return url;
      }
    },
  },
  created() {
    //获取导航栏列表
    this.getList();
    //获取导航栏logo
    this.getLogo();
  },
  methods: {
    // t为地址，id为文章id
    setRouter(t, id) {
      this.isClick = false;
      this.$router.push({ name: t, query: { id: id } });
    },
    openUrl(url) {
      if (!/^https?:\/\//i.test(url)) {
        // 如果不包含，则在字符串前加上 "https://"
        url = "https://" + url;
      }
      this.isClick = false;
      window.open(url);
    },
    //获取导航栏列表
    getList() {
      let data = {
        id: 234,
        current: 1,
        size: 15,
        data: {},
      };
      api.getColumnt(data).then((res) => {
        // console.log(res.records, "顶部导航栏");
        this.headItemList = res.records;
      });
    },
    //获取导航栏logo
    getLogo() {
      let data = {
        id: 226,
        current: 1,
        size: 5,
        data: {},
      };
      api.getColumnt(data).then((res) => {
        // console.log(res.records, "顶部logo");
        this.logoInfo = res.records[0];
      });
    },
  },
};
</script>



<style lang="scss" scoped>
.selected {
  font-weight: bold !important;
  color: #ff7226 !important;
}

.head-div {
  width: 100%;
  height: 80px;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;

  .head-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-logo {
      width: 292px;
      height: 57px;
    }
    // 首页、游戏产品、加入我们等
    .head-items {
      width: auto;
      height: 100%;
      padding-left: 64px;
      display: flex;
      align-items: center;
      text-wrap: nowrap;
      .head-item {
        font-weight: 400;
        font-size: 15px;
        color: #ffffff;
        line-height: 19px;
        margin: 0px 16px;
      }
    }
    $btn-top: 12px;
    // 移动端的选项按钮
    .head-moblie-btn {
      width: 30px;
      height: 30px;
      display: none;
      position: relative;
      span {
        display: block;
        width: 30px;
        height: 3px;
        background-color: #fff;
        border-radius: 9px;
        transition: all ease 0.35s;
        position: absolute;
        left: 0px;
      }
      span:nth-of-type(1) {
        top: 0px;
      }
      span:nth-of-type(2) {
        top: $btn-top;
      }
      span:nth-of-type(3) {
        top: $btn-top * 2;
      }
    }
    // 移动端选项按钮选中后的样式
    .head-moblie-btn-active {
      span:nth-of-type(1) {
        top: $btn-top;
        transform: rotate(45deg);
      }
      span:nth-of-type(2) {
        width: 0;
      }
      span:nth-of-type(3) {
        top: $btn-top;
        transform: rotate(-45deg);
      }
    }
    // 用户头像和用户名等
    .head-userInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      > div {
        width: 99px;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .userInfo-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
      }
      .userInfo-name {
        color: #ffffff;
      }
      .userInfo-btns {
        display: none;
        width: 109px;
        height: 90px;
        border-radius: 6px;
        background-color: #ffffff;
        overflow: hidden;
        position: absolute;
        z-index: 99;
        top: 100%;
        > div {
          width: 100%;
          height: 50%;
          font-weight: 400;
          font-size: 14px;
          color: #595959;
          line-height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
  